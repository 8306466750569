<template>
  <div class="project-sales-detail">
    <div class="container fluid">
      <sales-detail
        v-bind="{
          isAssigned,
          downloadAPI: downloadFile,
          getSalesDetailAPI: saleAPI.getTrackingData,
          cancelProjectSalesAPI: saleAPI.cancelProjectSales,
          updateBookingDocumentsAPI: saleAPI.updateBookingDocuments,
          updateSPADueDateAPI: saleAPI.updateSPADueDate
        }"
      ></sales-detail>
    </div>
  </div>
</template>

<script>
import { projectSales as saleAPI } from "@/api";
import { downloadFile } from "@/utils/download";

export default {
  components: {
    SalesDetail: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/SalesDetail"
      )
  },
  mixins: [],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      saleAPI,
      downloadFile,

      sales: {
        bankLoans: []
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
